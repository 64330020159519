import React, { useEffect, useState, useCallback } from "react";
import MultiProductCard from "./MultiProductCard";
import Typewriter from "../../CommonComponents/TypeWriting";
import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../../Services/Redux/Reducers/commonSlice';
import { storeChat } from '../../Services/Redux/Reducers/chatSlice';
import { getTitle } from "../../Services/CommonServices/helper";

const MultiCatTemplate = (props) => {
  const products = props.products;
  const cardLength = products?.length;
  const text ="Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.";
  const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
  const [isConclusionLoading, setIsConclusionLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.isLoader)
  const streamingState = useSelector((state) => state.common.stopStreaming)

  useEffect(() => {
    if(props.isAnswerComplete && loading) {
      // console.log('log',(props.text!=null && isOverviewTextLoading == true),(products==undefined || products.length == 0 ) ,(((props.conclusion && props.conclusion!="" && isConclusionLoading==true))));
      
        if(((props.text!=null && isOverviewTextLoading == true) && ((props.conclusion && props.conclusion!="" &&isConclusionLoading == true)) && ( products && cardLength && isProductLoading == true))) {
          dispatch(storeChat(true));
          dispatch(loader(false));    
        }
    }
    if(!isOverviewTextLoading && !isProductLoading && !isConclusionLoading && streamingState) {
      dispatch(loader(true));
    }
  }, [props.conclusion, props.isAnswerComplete, products, props.text, isOverviewTextLoading, isConclusionLoading, loading,isProductLoading])

  const categoryListing = (products,productIndex) => {
    if (products) {
      console.log("products : " , products)
      let data = {
        text: "Show me details for the "+ getTitle(products) + " look.",               //as per Peter's comments
        query: "Can you show me details of outfit with ID "+products.id+"?",
        type: props.responseType,
        };
      if(!props.stopClick){
          props?.onValueChange(data);
        }
    }
  };

  return (
    <div>
      <div class="row productdetails-div">
        <div className="col-12 col-lg-8 mb-4">
          <div className="mcat-text">
          {/* {props.text} */}
          <Typewriter convoId={props.convoId} type='responseText' text={props.text} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsOverviewTextLoading} effects={props.effects}/>
          </div>

        </div>
      </div>

      {
            ( isOverviewTextLoading) && <div className="col-12">
        <div className="cards">
          {products && products.map((productDetails, productIndex) => (
            <div
              onClick={() => {
                categoryListing(productDetails,productIndex);
              }}
              key={"productDetails" + productIndex}
            >
              <MultiProductCard
              // test="test123"
                key={productIndex}
                product={productDetails}
                productIndex={productIndex}
                cardLength={cardLength}
                convoId={props.convoId}
                isAnswerComplete={props.isAnswerComplete}
                effects={props.effects}
                delay={props.delay}
              />
            </div>
          ))}
        </div>
                  <Typewriter text={''} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsProductLoading} effects={props.effects}
          convoId={"props.convoId"} type='productDetail'  productIndex={0} propertyKey={"name"}/>
      </div>
}

{ ( isProductLoading ) && 
      <div className="row">
        <div className="mt-5 col-lg-9 pb-4 mb-3 mcat-text">
        <Typewriter convoId={props.convoId} type='responseConclusion' text={props.conclusion} delay={props.delay} isAnswerComplete={props.isAnswerComplete} setLoading={setIsConclusionLoading} effects={props.effects}/>
        </div>
      </div>
 } 
    </div>
  );
};

export default MultiCatTemplate;
