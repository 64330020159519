import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
// import { msalInstance } from '../Services/Authentication/auth_config';
// import { useMsal } from '@azure/msal-react';
import { useEffect, useState,useRef  } from 'react';
import { addPermittedUsers, deletePermittedUsers, userSession } from "../Services/ApiServices/userServices";
import { useDispatch } from 'react-redux';
import { loggedUserDetails, userIp } from '../Services/Redux/Reducers/commonSlice';
// import { CONFIG_URL } from '../Services/Settings/envConstants';
import { Cookies } from 'react-cookie-consent';

function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    // const { accounts } = useMsal();
    const name = Cookies?.get('ar_user_email')?Cookies.get('ar_user_email'):"";
    const dispatch = useDispatch();
    const [err,setError] = useState("");
    const effectRan = useRef(false);
    const [input, setInput] = useState("")
    const [message, setMessage] = useState([]);

    const fetchIpAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            dispatch(userIp(data.ip));
        } catch (err) {
            console.log(err)
        }
    }

    // useEffect(()=> {
    //     fetchIpAddress();
    //     if (!effectRan.current) {
    //     if(accounts.length !== 0) {
    //         if(accounts[0]?.name.includes(',')){
    //             let firstname = accounts[0]?.name.split(',')[1];
    //             firstname = firstname.split(' ')[1];
    //             let lastname = accounts[0]?.name.split(',')[0];
    //             setName(firstname+" "+lastname);
    //         } else {
    //             let firstname = accounts[0]?.name;
    //             setName(firstname);
    //         }
    //         let loggedUserAccounts = {
    //             name: accounts[0]?.name,
    //             // shortName: "",
    //             userId: accounts[0]?.username
    //         }
    //         dispatch(loggedUserDetails(loggedUserAccounts));
    //     }
    //     saveUserSession("Login");  //Saving the users
    // }        
    //     return () => effectRan.current = true;
    // }, [accounts]);
    


    // const saveUserSession = (status) => {    
    //     if(accounts.length !== 0) {
    //         if(accounts[0]?.username){
    //             const params = {
    //              username : accounts[0]?.name,            
    //              timeStamp : new Date(),
    //              status : status,
    //              userId : accounts[0]?.username,
    //              idToken:accounts[0]?.idToken
    //             }
    //             userSession(params,(response) => {                    
    //             }, (err) => {
    //               setError(err.message);
    //             });
    //         } 
    //     }       
    // }

    const handleClick = () => {
        window.open('https://www.ralphlauren.global/in/en/cart', '_blank', 'noopener,noreferrer')
    };

    const handleRedirect = () => {
        navigate("/");
    };

    const handleLogout = () => {        
        // saveUserSession("Logout"); //Saving user info
        // // msalInstance.logoutRedirect();
        // localStorage.clear();
        // navigate("/");
    };

    const add_delete_users = (type) => {
        onClose();
        if(input){
            let emails = []
            if(input.includes(",")) {
                emails = input.split(',').map(i => i.trim());
            } else {
                emails = [input]
            }
            let params = {
                users: emails
            }
            if(type === "add") {
                addPermittedUsers(params, (res) => {
                    setMessage(res.data.results)
                    setInput("")
                },((err) => {
                    setError("Something went wrong")
                }))
            } else {
                deletePermittedUsers(params, (res) => {
                    setMessage(res.data.results)
                    setInput("")
                },((err) => {
                    setError("Something went wrong")
                }))
            }
        } else {
            setError("Email is required")
        }
    }
    
    const onClose = () => {
        setError("")
        setMessage([]);
        setInput("");
    }

    return (
        <div className={`header ${location.pathname === "/" && "header_transparent"}`}>
            <div className="header_options d-flex aligin-items-center justify-content-between">
                <div className="header_logo">
                {location.pathname !== "/" &&
                    // <img src="/static/assets/rl_logo.svg" alt="rl_logo" onClick={handleRedirect} />
                        <button
                        className="close-chat-btn"
                        onClick={()=>{handleRedirect()}}
                        >
                        <span>Close Chat</span>
                        </button>
                }
                </div>
                <div className="d-flex align-items-center">
                    <div className="icon-dist">
                        <img width={20} height={20} className="cart-icon" src={location.pathname === "/" ?  "/static/assets/cartwhite.svg" : "/static/assets/cart.svg"} alt="cart" onClick={() => { handleClick() }}/>
                    </div>
                    <div type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <img className="hamburger-menu-icon" src= {location.pathname === "/"? "/static/assets/menu_icon.svg":"/static/assets/chat_menu_icon.svg"} alt="menu" />
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header d-flex justify-content-end">
                    <button type="button" className="menu-close-icon me-2 mt-2" data-bs-dismiss="offcanvas" ><img src="/static/assets/close.svg" alt="close" onClick={() => onClose()} /></button>
                </div>
                <div className="sidebar-body offcanvas-body">
                    <div className="usersection px-3 py-2">
                        {name && 
                            <div className="d-flex align-items-center">
                                <img src="./static/assets/Account.svg" alt="profile" className='profile-icon' />
                                <div className="username">{name}</div>
                            </div>
                        }
                        <div className="faq-div">
                            <div>FAQ's</div>
                        </div>
                        {/* <div className="signout-div" onClick={handleLogout}>
                            <div>Sign Out</div>
                        </div> */}
                        <hr/>
                        <div>
                            <div className="foundersGroteskTextMedium policy">PRIVACY POLICY & TERMS AND CONDITIONS:</div>
                        </div>
                        <div className='foundersGroteskTextRegular'>
                            <div className="userterms">For more information on how we use your personal information, consult our <a href="https://www.ralphlauren.global/in/en/customerservice?cid=cs-privacy-notice" target="_blank" rel="noopener noreferrer">Privacy Notice</a> and <a href="https://www.ralphlauren.global/in/en/customerservice?cid=cs-terms-of-use" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.</div>
                        </div>
                    </div>
                    {/* {(CONFIG_URL.developers.includes(accounts[0]?.username)) &&
                        <div className="ms-2 mt-4">
                            <textarea 
                            id="text_id"
                            className="form-control"
                            placeholder="name@example.com"
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            // value=""
                            // onChange=""
                            />
                            <div className="mt-3 d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" onClick={()=> add_delete_users("add")}>Add</button>
                                <span>
                                    <button type="button" class="btn btn-primary" onClick={() => add_delete_users("delete")}>Delete</button>
                                </span>
                            </div>
                            <div>
                                {err && 
                                    <div className='text-danger'>
                                        {err}
                                    </div>
                                }
                                {message && message.map((x) => (
                                    <div>
                                        {x.user} - {x.message}
                                    </div>
                                ))}
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        
        </div>
    );
}

export default Header;