import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from "@azure/msal-react";
// import { loginRequest, msalInstance } from "./Services/Authentication/auth_config";
// import { InteractionType } from "@azure/msal-browser";
import Useronboard from "./Components/Useronboard";
import MainLayout from "./Layouts/MainLayout";
// import PrivateRoute from "./Routes/PrivateRoute";
import PageNotFound from "./CommonComponents/PageNotFound";
import Chat from "./Components/Chat";
// import Restricted from "./CommonComponents/Restricted";
// import ErrorComponent from "./CommonComponents/ErrorComponent";
import ChatHistory from "./CommonComponents/ChatHistory";

function App() {

  return (
    // <MsalProvider instance={msalInstance}>
    //   <MsalAuthenticationTemplate 
    //         interactionType={InteractionType.Redirect} 
    //         authenticationRequest={loginRequest} 
    //         errorComponent={ErrorComponent}
    //     >
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={
            // <PrivateRoute>
              <Useronboard />
            // </PrivateRoute>
            } 
          />
          <Route
            path="/chat"
            element={
              // <PrivateRoute>
                <Chat />
              // </PrivateRoute>
            }
          />
          <Route
            path="/chatHistory"
            element={
              // <PrivateRoute>
                <ChatHistory />
              // </PrivateRoute>
            }
          />
        </Route>
        {/* <Route path="/unauthorized" element={<Restricted />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
    // </MsalAuthenticationTemplate>
    // </MsalProvider>
  );
}

export default App;
